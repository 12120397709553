import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a7dbd58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body w_1200" }

import LeftContent from './components/LeftContent.vue'

export default {
  setup(__props) {


return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LeftContent),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view)
    ])
  ]))
}
}

}